import { getEnv } from "@/configs/config";
import axios from "axios";

const myspilcomapi = {
  listFaqNew2(header, body) {
    return axios
      .post(
        `${getEnv(
          process.env.MYSPILCOM_API_URL
        )}/func/listfaqnew2.json`,
        JSON.stringify(body) //this is the request body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        let res = err.response;
        return res;
      });
  },
  spilcoidContactUs(header, body) {
    return axios
      .post(
        `${getEnv(
          process.env.MYSPILCOM_API_URL
        )}/spilcoid/spilcoidcontactus.json`,
        JSON.stringify(body) //this is the request body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        let res = err.response;
        return res;
      });
  },
  spilcoidEsgOtpEmail(header, body) {
    return axios
      .post(
        `${getEnv(
          process.env.MYSPILCOM_API_URL
        )}/spilcoid/spilcoidesgotpemail.json`,
        JSON.stringify(body) //this is the request body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        let res = err.response;
        return res;
      });
  },
};

export default myspilcomapi;
