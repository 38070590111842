const sanitizeInput = (inp) => {
  inp = inp.replace("'", "&quot");
  // inp = inp.replace('\"','&dquot');
  inp = inp.replace("=", "&equal");
  return inp;
};

const sanitizeWhitespace = (inp) => {
  return /\s/.test(inp);
};

const containsKey = (obj, key) => {
  return Object.keys(obj).includes(key)
}

const OtherPlugins = {
  sanitizeInput,
  sanitizeWhitespace,
  containsKey
};

export default OtherPlugins;
