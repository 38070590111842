<template>
  <div class="footer py-12">
    <v-container>
      <v-row>
        <!-- SPIL Headline -->
        <v-col cols="12" lg="5" class="d-sm-flex justify-center mb-6">
          <div class="d-flex headline">
            <img
              src="@/assets/spil_logo.svg"
              alt="shipping, shiplog, logistik, cargo, ekspedisi, kontainer, freight, SPIL"
              class="mr-8 logo"
            />
            <div>
              <div class="text-white text-h5">
                PT Salam Pacific Indonesia Lines
              </div>
              <div class="text-white text-h6 mt-6 d-none d-sm-flex">
                SPIL Customer Service
              </div>
              <div
                class="text-white text-body-2 font-weight-medium mt-4 d-none d-sm-flex"
              >
                Call : 081-163-3388 | Whatsapp : 081-133-3799
              </div>
            </div>
          </div>

          <div class="d-block d-sm-none">
            <div class="text-white text-h6 mt-6">SPIL Customer Service</div>
            <div class="text-white text-body-2 font-weight-medium mt-4">
              Call : 081-163-3388 | Whatsapp : 081-133-3799
            </div>
          </div>
        </v-col>

        <!-- Menu List -->
        <v-col cols="12" sm="6" lg="3">
          <div
            v-for="(menu, idx) in footerMenus"
            :key="idx"
            class="mb-4 d-inline-block w-50"
          >
            <router-link
              :to="menu.href[currLang]"
              @click="setActivePage(menu.href[currLang])"
              class="text-decoration-none text-white text-body-2 font-weight-medium pr-2"
            >
              {{ this.$root.lang(menu.label) }}
            </router-link>
          </div>
        </v-col>

        <!-- CTA -->
        <v-col class="text-center">
          <v-row>
            <v-col
              cols="12"
              sm="6"
              lg="5"
              class="d-flex justify-space-around d-sm-block"
            >
              <div v-for="(store, idx) in appsStore" :key="idx">
                <a
                  :href="store.link"
                  target="_blank"
                  class="text-decoration-none"
                  @click="
                    () =>
                      saveTrackAnalytics(
                        'CLICK CTA',
                        `Click ${store.label} Button`,
                        store.link,
                        '',
                        '',
                        '',
                        ''
                      )
                  "
                >
                  <img
                    :src="store.img"
                    :alt="store.alt"
                    width="140"
                    class="mb-sm-4"
                  />
                </a>
              </div>
            </v-col>

            <!-- FOR SM BELOW -->
            <v-col
              cols="12"
              sm="6"
              lg="7"
              class="d-flex d-sm-none justify-space-around"
            >
              <div v-for="(media, idx) in socialMedias" :key="idx">
                <a
                  :href="media.link"
                  target="_blank"
                  class="text-decoration-none"
                  @click="
                    () =>
                      saveTrackAnalytics(
                        'CLICK SOCIAL MEDIA',
                        `Click SPIL ${media.label} Button`,
                        media.link,
                        '',
                        '',
                        '',
                        ''
                      )
                  "
                >
                  <img
                    :src="media.img"
                    :alt="media.alt"
                    width="48"
                    class="m-2"
                  />
                </a>
              </div>
            </v-col>

            <!-- FOR SM ABOVE -->
            <v-col cols="12" sm="6" lg="7" class="d-none d-sm-block">
              <div class="mb-4">
                <a
                  :href="socialMedias[0].link"
                  target="_blank"
                  class="text-decoration-none"
                  @click="
                    () =>
                      saveTrackAnalytics(
                        'CLICK SOCIAL MEDIA',
                        `Click SPIL ${socialMedias[0].label} Button`,
                        socialMedias[0].link,
                        '',
                        '',
                        '',
                        ''
                      )
                  "
                >
                  <img
                    :src="socialMedias[0].img"
                    :alt="socialMedias[0].alt"
                    width="48"
                    class="mr-4"
                  />
                </a>
                <a
                  :href="socialMedias[1].link"
                  target="_blank"
                  class="text-decoration-none"
                  @click="
                    () =>
                      saveTrackAnalytics(
                        'CLICK SOCIAL MEDIA',
                        `Click SPIL ${socialMedias[1].label} Button`,
                        socialMedias[1].link,
                        '',
                        '',
                        '',
                        ''
                      )
                  "
                >
                  <img
                    :src="socialMedias[1].img"
                    :alt="socialMedias[1].alt"
                    width="48"
                  />
                </a>
              </div>

              <div>
                <a
                  :href="socialMedias[2].link"
                  target="_blank"
                  class="text-decoration-none"
                  @click="
                    () =>
                      saveTrackAnalytics(
                        'CLICK SOCIAL MEDIA',
                        `Click SPIL ${socialMedias[2].label} Button`,
                        socialMedias[2].link,
                        '',
                        '',
                        '',
                        ''
                      )
                  "
                >
                  <img
                    :src="socialMedias[2].img"
                    :alt="socialMedias[2].alt"
                    width="48"
                    class="mr-4"
                  />
                </a>
                <a
                  :href="socialMedias[3].link"
                  target="_blank"
                  class="text-decoration-none"
                  @click="
                    () =>
                      saveTrackAnalytics(
                        'CLICK SOCIAL MEDIA',
                        `Click SPIL ${socialMedias[3].label} Button`,
                        socialMedias[3].link,
                        '',
                        '',
                        '',
                        ''
                      )
                  "
                >
                  <img
                    :src="socialMedias[3].img"
                    :alt="socialMedias[3].alt"
                    width="48"
                  />
                </a>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- Copy Right -->
      <div
        class="w-100 text-center text-white text-body-2 font-weight-medium mt-6"
      >
        © 2024 PT SALAM PACIFIC INDONESIA LINES. All rights reserved
      </div>
    </v-container>
  </div>
</template>

<script>
import MyPlugin from "@/plugins/plugin";
import { mapActions } from "pinia";
import { useGeneralStore } from "@/stores/GeneralStore";
import { useTrackAnalyticsStore } from "@/stores/TrackAnalyticsStore";
import { useUserStore } from "@/stores/UserStore";

export default {
  name: "FooterNav",
  components: {},
  data() {
    return {
      currLang: "ind",
      footerMenus: [
        {
          label: "navAbout",
          href: { ind: "/tentang-spil", eng: "/tentang-spil" },
        },
        {
          label: "navNews",
          href: { ind: "/news", eng: "/news" },
        },
        {
          label: "navMyspilReloaded",
          href: { ind: "/my-spil-reloaded", eng: "/my-spil-reloaded" },
        },
        {
          label: "navCareer",
          href: { ind: "/careers", eng: "/careers" },
        },
        {
          label: "navSpilDex",
          href: { ind: "/spildex", eng: "/spildex" },
        },
        // {
        //   label: "navOutlook",
        //   href: { ind: "/wawasan", eng: "/outlook" },
        // },
        {
          label: "navContact",
          href: { ind: "/contact", eng: "/contact" },
        },
      ],
      appsStore: [
        {
          img: require("@/assets/images/icons/downloadBadge_Googleplay.svg"),
          alt: "shipping, shiplog, logistik, cargo, ekspedisi, kontainer, freight, SPIL",
          link: "https://play.google.com/store/search?q=myspil+reloaded&c=apps",
          label: "Google Play",
        },
        {
          img: require("@/assets/images/icons/downloadBadge_Appstore.svg"),
          alt: "shipping, shiplog, logistik, cargo, ekspedisi, kontainer, freight, SPIL",
          link: "https://apps.apple.com/id/app/myspil-reloaded/id6446185688?l=id",
          label: "Apps Store",
        },
      ],

      socialMedias: [
        {
          img: require("@/assets/images/icons/Icon_Facebook_White.svg"),
          alt: "shipping, shiplog, logistik, cargo, ekspedisi, kontainer, freight, SPIL",
          link: "https://www.facebook.com/ptspil/",
          label: "Facebook",
        },
        {
          img: require("@/assets/images/icons/Icon_LinkedIn_White.svg"),
          alt: "shipping, shiplog, logistik, cargo, ekspedisi, kontainer, freight, SPIL",
          link: "https://www.linkedin.com/company/pt-salam-pacific-indonesia-lines/",
          label: "Linkedin",
        },
        {
          img: require("@/assets/images/icons/Icon_Instagram_White.svg"),
          alt: "shipping, shiplog, logistik, cargo, ekspedisi, kontainer, freight, SPIL",
          link: "https://www.instagram.com/ptspil/",
          label: "Instagram",
        },
        {
          img: require("@/assets/images/icons/Icon_Youtube_White.svg"),
          alt: "shipping, shiplog, logistik, cargo, ekspedisi, kontainer, freight, SPIL",
          link: "https://www.youtube.com/channel/UCXWGx_dFWjhLVG8x85xNpCA",
          label: "Youtube",
        },
      ],
    };
  },
  methods: {
    ...mapActions(useGeneralStore, ["setActivePage"]),

    containsKey(obj, key) {
      return MyPlugin.OtherPlugins.containsKey(obj, key);
    },

    saveTrackAnalytics(action, remark, val1, val2, val3, val4, val5) {
      let userStore = useUserStore();
      let trackAnalyticsStore = useTrackAnalyticsStore();

      let pathName =
        this.$route && this.$route.fullPath ? this.$route.fullPath : "";
      let pathParams =
        this.$route && this.$route.params
          ? JSON.stringify(this.$route.params)
          : "";
      let pathModule =
        this.$route && this.$route.meta && this.$route.meta.module
          ? this.$route.meta.module
          : "";

      let saveTrackAnalyticsParam = {
        user_id: userStore.$state.userId,
        customer_id: userStore.$state.customerId,
        action: action,
        module: pathModule,
        remark: remark,
        ippublic: "",
        iplocal: "",
        value1: val1 ? val1 : pathName,
        value2: val2 ? val2 : pathParams,
        value3: val3 ? val3 : "",
        value4: val4 ? val4 : "",
        value5: val5 ? val5 : "",
      };
      trackAnalyticsStore.saveTrackAnalytics(saveTrackAnalyticsParam);
    },
  },
  mounted() {
    this.currLang = MyPlugin.LangPlugins.isIndonesia() ? "ind" : "eng";
  },
};
</script>

<style scoped>
img {
  cursor: pointer;
}
.logo {
  width: 96px;
  height: 64px;
  transform: translateY(-20%);
}
.footer {
  background: linear-gradient(83deg, #3a9542 -12.59%, #2a695b 162.84%);
}

@media screen and (max-width: 600px) {
  .logo {
    width: 73px;
    height: 48px;
    transform: translateY(0%);
  }
  .text-h5 {
    font-size: 1.3rem !important;
    line-height: 1.8rem;
  }
  .text-h6 {
    font-size: 1rem !important;
    line-height: 1.5rem;
  }
  .text-body-2 {
    font-size: 1rem !important;
    line-height: 1.4rem;
  }
  .headline {
    align-items: center;
  }
}
</style>
