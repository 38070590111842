<template>
  <metainfo>
    <template v-slot:title="{ content }">{{
      content ? `${content} | SPIL` : `SPIL | PT Salam Pacific Indonesia Lines`
    }}</template>
  </metainfo>

  <AppLayout> <router-view /> </AppLayout>
</template>

<script>
import { useMeta } from "vue-meta";
import AppLayout from "./views/layouts/AppLayout.vue";
import MyPlugin from "./plugins/plugin";

export default {
  components: { AppLayout },
  computed: {},
  watch: {},
  setup() {
    useMeta({
      title: "",
      htmlAttrs: { lang: "en", amp: true },
    });
  },
  methods: {
    lang(key) {
      return MyPlugin.LangPlugins.lang(key);
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Georama:wght@100;200;300;400;500;600;700;800;900&display=swap");

html,
body {
  height: 100%;
  width: 100%;
  margin: 0 !important;
}
#app {
  height: 100%;
  width: 100%;
}
</style>
