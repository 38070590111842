<template>
  <div>
    <v-app-bar
      :elevation="0"
      height="72"
      class="user-select-none"
      :class="drawer ? '' : 'app-bar'"
    >
      <v-container class="d-flex">
        <!-- SPIL Logo -->
        <router-link
          to="/"
          @click="() => setActivePage('/')"
          class="text-decoration-none"
        >
          <img
            src="@/assets/spil_logo.svg"
            alt="SPIL Logo"
            width="70"
            height="45"
          />
        </router-link>

        <!-- Menu List -->
        <div class="w-100 d-none d-lg-flex justify-end align-center mr-5">
          <div v-for="(menu, idx) in navMenus" :key="idx" class="ml-12">
            <!-- Dropdown Menu -->
            <div
              v-if="containsKey(menu, 'items')"
              :id="menu.label"
              class="text-nav cursor-pointer d-flex align-center"
              :class="
                menu.items
                  .map((item) => {
                    if (containsKey(item, 'href')) return item.href[currLang];
                  })
                  .includes(activePage) ||
                activePage.includes(this.$root.lang(menu.label).toLowerCase())
                  ? 'text-secondary'
                  : 'text-text-default nav-hover'
              "
            >
              <img
                v-if="menu.key == 'lang'"
                :src="currLangImg"
                alt="Lang Flag"
                width="20"
                height="20"
                class="mr-2"
              />
              {{ this.$root.lang(menu.label) }}
              <img
                src="@/assets/images/icons/arrow_down.svg"
                alt="dropdown icon"
                class="ml-2"
                style="transition: 0.5s ease"
                :style="
                  menuOpen[idx] != null && menuOpen[idx] == true
                    ? 'transform: rotate(-180deg);'
                    : ''
                "
              />
            </div>

            <!-- Basic Menu -->
            <div v-else>
              <router-link
                :to="menu.href[currLang]"
                @click="setActivePage(menu.href[currLang])"
                class="text-decoration-none text-nav"
                :class="
                  activePage == menu.href[currLang]
                    ? 'text-secondary'
                    : 'text-text-default nav-hover'
                "
                >{{ this.$root.lang(menu.label) }}
              </router-link>
            </div>
          </div>
        </div>

        <!-- SEARCH -->
        <!-- <div class="ml-8 d-none d-lg-flex align-center">
          <img src="@/assets/images/icons/search.svg" class="cursor-pointer" />
        </div> -->

        <!-- MOBILE -->
        <div class="w-100 d-flex d-lg-none justify-end">
          <v-btn
            icon="mdi mdi-menu"
            :ripple="false"
            @click="drawer = !drawer"
            aria-label="drawer button"
          />
        </div>
      </v-container>
    </v-app-bar>

    <!-- MENU DROPDOWN LIST -->
    <div v-for="(menu, idx) in navMenus" :key="idx">
      <v-menu
        v-if="containsKey(menu, 'items')"
        :activator="'#' + menu.label"
        v-model="menuOpen[idx]"
        location="center"
        style="z-index: 0"
      >
        <v-list class="mt-14 rounded-b-xl" elevation="0">
          <v-list-item
            v-for="(item, idx) in menu.items"
            :key="idx"
            :value="idx"
          >
            <!-- Change Language -->
            <div
              v-if="containsKey(item, 'icon')"
              @click="setLanguage(item.value)"
              class="text-decoration-none text-nav text-text-default d-flex align-center nav-hover"
            >
              <img
                :src="item.icon"
                alt="Lang Flag"
                width="20"
                height="20"
                class="mr-3"
              />
              {{ this.$root.lang(item.label) }}
            </div>

            <!-- Regular Options -->
            <router-link
              v-else
              :to="item.href[currLang]"
              @click="setActivePage(item.href[currLang])"
              class="text-decoration-none text-nav d-flex align-center"
              :class="
                activePage == item.href[currLang] ||
                activePage.includes(this.$root.lang(item.label).toLowerCase())
                  ? 'text-secondary'
                  : 'text-text-default nav-hover'
              "
            >
              {{ this.$root.lang(item.label) }}
            </router-link>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <!-- MENU DRAWER -->
    <v-navigation-drawer
      v-model="drawer"
      location="top"
      class="w-100 h-100 d-block d-lg-none"
      color="#eee"
    >
      <v-container class="mt-6">
        <v-list-item
          v-for="(menu, idx) in navMenus"
          :key="idx"
          class="cursor-pointer text-text-default font-weight-medium pa-0"
        >
          <div>
            <!-- Dropdown Menu -->
            <div
              v-if="containsKey(menu, 'items')"
              @click="() => (menuOpen[idx] = true)"
              :id="menu.label"
              class="text-nav cursor-pointer d-flex justify-space-between align-center"
              :class="
                menu.items
                  .map((item) => {
                    if (containsKey(item, 'href')) return item.href[currLang];
                  })
                  .includes(activePage) ||
                activePage.includes(menu.label.toLowerCase())
                  ? 'text-secondary'
                  : 'text-text-default nav-hover'
              "
            >
              <div class="d-flex align-center">
                <img
                  v-if="menu.key == 'lang'"
                  :src="currLangImg"
                  alt="Lang Flag"
                  width="20"
                  height="20"
                  class="mr-2"
                />
                {{ this.$root.lang(menu.label) }}
              </div>

              <img
                src="@/assets/images/icons/arrow_down.svg"
                alt="dropdown icon"
                class="ml-2"
                style="transition: 0.5s ease"
                :style="
                  menuOpen[idx] != null && menuOpen[idx] == true
                    ? 'transform: rotate(-180deg);'
                    : ''
                "
              />
            </div>

            <!-- Basic Menu -->
            <router-link
              v-else
              :to="menu.href[currLang]"
              @click="setActivePage(menu.href[currLang])"
              class="text-decoration-none text-nav"
              :class="
                activePage == menu.href[currLang]
                  ? 'text-secondary'
                  : 'text-text-default nav-hover'
              "
            >
              <div>{{ this.$root.lang(menu.label) }}</div>
            </router-link>
          </div>

          <!-- Mobile Menu List -->
          <div v-show="menuOpen[idx]">
            <div
              v-for="(item, idx) in menu.items"
              :key="idx"
              class="d-flex align-center ml-4 my-2"
            >
              <!-- Change Language  -->
              <div
                v-if="containsKey(item, 'icon')"
                @click="setLanguage(item.value)"
                class="text-decoration-none text-nav text-text-subdued d-flex align-center nav-hover"
              >
                <img
                  :src="item.icon"
                  alt="Lang Flag"
                  width="20"
                  height="20"
                  class="mr-3"
                />
                {{ this.$root.lang(item.label) }}
              </div>

              <!-- Regular Options -->
              <router-link
                v-else
                :to="item.href[currLang]"
                @click="setActivePage(item.href[currLang])"
                class="text-decoration-none text-nav d-flex align-center"
                :class="
                  activePage == item.href[currLang] ||
                  activePage.includes(this.$root.lang(item.label).toLowerCase())
                    ? 'text-secondary'
                    : 'text-text-subdued nav-hover'
                "
              >
                {{ this.$root.lang(item.label) }}
              </router-link>
            </div>
          </div>
        </v-list-item>
      </v-container>
    </v-navigation-drawer>
  </div>
</template>

<script>
import MyPlugin from "@/plugins/plugin";
import { mapActions, mapState } from "pinia";
import { useGeneralStore } from "@/stores/GeneralStore";

export default {
  name: "HeaderNav",
  components: {},
  data() {
    return {
      drawer: false,
      menuOpen: [],
      currLang: "ind",
      navMenus: [
        {
          label: "navHome",
          href: { ind: "/", eng: "/" },
        },
        {
          label: "navCommitment",
          href: { ind: "/komitmen", eng: "/commitment" },
        },
        {
          label: "navCompany",
          items: [
            {
              label: "navAbout",
              href: { ind: "/tentang-spil", eng: "/tentang-spil" },
            },
            {
              label: "navHistory",
              href: { ind: "/perusahaan/sejarah", eng: "/company/history" },
            },
            {
              label: "navGroup",
              href: { ind: "/perusahaan/grup", eng: "/company/group" },
            },
            {
              label: "navCareer",
              href: { ind: "/careers", eng: "/careers" },
            },
          ],
        },
        {
          label: "navServices",
          items: [
            {
              label: "navServicesType",
              href: { ind: "/services", eng: "/services" },
            },
            {
              label: "navGuide",
              href: { ind: "/layanan/panduan", eng: "/service/guide" },
            },
            {
              label: "navRoute",
              href: { ind: "/routes-2", eng: "/routes-2" },
            },
          ],
        },
        {
          label: "navContact",
          href: { ind: "/contact", eng: "/contact" },
        },
        {
          label: "navMyspilReloaded",
          href: { ind: "/my-spil-reloaded", eng: "/my-spil-reloaded" },
        },
        {
          key: "lang",
          label: "navLang",
          items: [
            {
              icon: require("@/assets/images/icons/indonesia.png"),
              label: "navID",
              value: "indonesia",
            },
            {
              icon: require("@/assets/images/icons/united-kingdom.png"),
              label: "navENG",
              value: "english",
            },
          ],
        },
      ],
      otherMenus: [
        {
          href: { ind: "/news", eng: "/news" },
        },
        {
          href: { ind: "/spildex", eng: "/spildex" },
        },
      ],
    };
  },
  computed: {
    ...mapState(useGeneralStore, ["activePage"]),

    currLangImg() {
      return this.currLang == "ind"
        ? require("@/assets/images/icons/indonesia.png")
        : require("@/assets/images/icons/united-kingdom.png");
    },
  },
  methods: {
    ...mapActions(useGeneralStore, ["toggleLanguage", "setActivePage"]),

    containsKey(obj, key) {
      return MyPlugin.OtherPlugins.containsKey(obj, key);
    },
    setLanguage(lang) {
      let currActiveMenu = this.activePage;
      const newLang = lang == "indonesia" ? "ind" : "eng";

      this.navMenus.forEach((menu) => {
        if (menu.key == "lang") return;

        if (menu.items) {
          menu.items.forEach((item) => {
            if (item.href[this.currLang] == this.activePage) {
              currActiveMenu = item.href[newLang];
            }
          });
        }

        if (menu.href && menu.href[this.currLang] == this.activePage) {
          currActiveMenu = menu.href[newLang];
        }
      });

      // If currActiveMenu equals null > the current menu is on footer nav
      if (!currActiveMenu) {
        this.otherMenus.forEach((menu) => {
          if (menu.href[this.currLang] == this.activePage) {
            currActiveMenu = menu.href[newLang];
          }
        });
      }

      this.currLang = newLang;
      this.setActivePage(currActiveMenu);
      this.toggleLanguage(lang);
    },
  },
  mounted() {
    this.currLang = MyPlugin.LangPlugins.isIndonesia() ? "ind" : "eng";

    const pathname = window.location.pathname;
    this.setActivePage(pathname);
  },
};
</script>

<style scoped>
.app-bar {
  background: rgba(255, 255, 255, 0.9) !important;
  backdrop-filter: blur(8px);
}
.nav-hover:hover {
  text-shadow: 0 0 1px #333, 0 0 1px #333;
}
</style>
