import { defineStore } from "pinia";
import mainAPI from "@/api/mainAPI";

export const useTrackAnalyticsStore = defineStore("TrackAnalyticsStore", {
  state: () => ({
    purposeMsg: [],
  }),
  getters: {},
  actions: {
    setPurpose(state) {
      this.purposeMsg = this.purposeMsg.concat(state);
    },


    


    async saveTrackAnalytics(params) {
      let body = {
        "apidata": {
            "apirequest": "SAVETRACKANALYTICS",
            "userid": "GUEST"
        },
        "params": params
    }

      // console.log("TEST MASUK")
      let res = await mainAPI.requestHandler(null, body);
      return res;
    },
  },
});
