import { defineStore } from "pinia";

export const useUserStore = defineStore("UserStore", {
  state: () => ({
    userId: "GUEST",
    customerId: "",
  }),
  getters: {},
  actions: {
    setPurpose(state) {
      this.purposeMsg = this.purposeMsg.concat(state);
    },
  },
  persist: {
    storage: sessionStorage,
    paths: [],
  },
});
