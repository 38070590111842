import { getEnv } from "@/configs/config";
import axios from "axios";

const mainAPI = {
  requestHandler(header, body) {
    return axios
      .post(
        `${getEnv(process.env.BASE_API_URL)}/requesthandler`,
        JSON.stringify(body), //this is the request body
        {
          headers: header,
        }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        let res = err.response;
        return res;
      });
  },
};

export default mainAPI;
