const checkValidEmail = (email) => {
  return /.+@.+\..+/.test(email);
};

const checkValidPhoneNumber = (phoneNumber) => {
  var isValid = false;
  const cleanPhoneNumber = phoneNumber.replace(/[^0-9-]/g, "");

  // Check if the number has 10 to 12 digits
  if (cleanPhoneNumber.length < 9 || cleanPhoneNumber.length > 12) {
    isValid = false;
  } else {
    isValid = true;
  }

  return isValid;
};

const checkWhiteSpace = (val) => {
  return /^\s*$/.test(val);
};

const ValidatorPlugins = {
  checkValidEmail,
  checkValidPhoneNumber,
  checkWhiteSpace,
};

export default ValidatorPlugins;
