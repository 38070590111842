import { defineStore } from "pinia";
import myspilcomapi from "@/api/myspilcomapi";

export const useContactStore = defineStore("ContactPurpose", {
  state: () => ({
    purposeMsg: [],
  }),
  getters: {},
  actions: {
    setPurpose(state) {
      this.purposeMsg = this.purposeMsg.concat(state);
    },


    async contactUsSendEmail(params) {
      // console.log("TEST MASUK")
      let res = await myspilcomapi.spilcoidContactUs(null, params);
      return res;
    },
  },
});
