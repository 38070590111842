import { createRouter, createWebHistory } from "vue-router";
import { useTrackAnalyticsStore } from "@/stores/TrackAnalyticsStore";
// import { useGeneralStore } from "@/stores/GeneralStore";
import { useUserStore } from "@/stores/UserStore";
// import languages from "@/configs/language";

function lazyLoad(view) {
  return () => import(`@/views/pages/${view}.vue`);
}

const routes = [
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: lazyLoad("NotFound"),
    redirect: "/",
    meta:{
      // here
      module: "NOTFOUND",
    }
  },
  {
    path: "/",
    name: "home",
    component: lazyLoad("HomePage"),
    meta:{
      module: "HOME",
    }
  },
  {
    path: "/commitment",
    alias: "/komitmen",
    name: "commitment",
    component: lazyLoad("CommitmentPage"),
    meta:{
      module: "COMMITMENT",
    }
  },
  {
    path: "/tentang-spil",
    name: "about-company",
    component: lazyLoad("company/AboutCompanyPage"),
    meta:{
      module: "COMPANY",
    }
  },
  {
    path: "/company/history",
    alias: "/perusahaan/sejarah",
    name: "history-company",
    component: lazyLoad("company/HistoryCompanyPage"),
    meta:{
      module: "COMPANY",
    }
  },
  {
    path: "/company/group",
    alias: "/perusahaan/grup",
    name: "group-company",
    component: lazyLoad("company/GroupCompanyPage"),
    meta:{
      module: "COMPANY",
    }
  },
  {
    path: "/careers",
    name: "career-company",
    component: lazyLoad("company/CareerCompanyPage"),
    meta:{
      module: "COMPANY",
    }
  },
  {
    path: "/company/career/:slug",
    alias: "/perusahaan/karir/:slug",
    name: "career-detail-company",
    component: lazyLoad("company/CareerDetailCompanyPage"),
    meta:{
      module: "COMPANY",
    }
  },
  {
    path: "/services",
    name: "type-service",
    component: lazyLoad("service/TypeServicePage"),
    meta:{
      module: "SERVICE",
    }
  },
  {
    path: "/service/guide",
    alias: "/layanan/panduan",
    name: "guide-service",
    component: lazyLoad("service/GuideServicePage"),
    meta:{
      module: "SERVICE",
    }
  },
  {
    path: "/service/guide/:slug",
    alias: "/layanan/panduan/:slug",
    name: "guide-detail-service",
    component: lazyLoad("service/GuideDetailServicePage"),
    meta:{
      module: "SERVICE",
    }
  },
  {
    path: "/service/guide/:topicid/:subtopicid",
    alias: "/layanan/panduan/:topicid/:subtopicid",
    name: "guide-detail-service-new",
    component: lazyLoad("service/GuideDetailServicePage"),
    meta:{
      module: "SERVICE",
    }
  },
  {
    path: "/routes-2",
    name: "route-service",
    component: lazyLoad("service/RouteServicePage"),
    meta:{
      module: "SERVICE",
    }
  },
  {
    path: "/contact",
    name: "contact-us",
    component: lazyLoad("ContactUsPage"),
    meta:{
      module: "CONTACT",
    }
  },
  {
    path: "/my-spil-reloaded",
    name: "myspilreloaded",
    component: lazyLoad("MySPILReloadedPage"),
    meta:{
      module: "MYSPILRELOADED",
    }
  },
  {
    path: "/news",
    name: "news",
    component: lazyLoad("news/NewsPage"),
    meta:{
      module: "NEWS",
    }
  },
  {
    path: "/news/:slug",
    name: "news-detail",
    component: lazyLoad("news/NewsDetailPage"),
    meta:{
      module: "NEWS",
    }
  },
  {
    path: "/outlook",
    alias: "/wawasan",
    name: "outlook",
    component: lazyLoad("outlook/OutlookPage"),
    meta:{
      module: "OUTLOOK",
    }
  },
  {
    path: "/outlook/:slug",
    alias: "/wawasan/:slug",
    name: "outlook-detail",
    component: lazyLoad("outlook/OutlookDetailPage"),
    meta:{
      module: "OUTLOOK",
    }
  },
  {
    path: "/spildex",
    name: "spildex",
    component: lazyLoad("SPILDexPage"),
    meta:{
      module: "SPILDEX",
    }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0, left: 0 };
  },
});

router.beforeEach((to) => {
  // let generalStore = useGeneralStore();
  let trackAnalyticsStore = useTrackAnalyticsStore();
  let userStore = useUserStore();

  let pathName = to && to.fullPath ? to.fullPath : "";
  let pathParams = to && to.params ? JSON.stringify(to.params) : "";
  let pathModule = to && to.meta && to.meta.module ? to.meta.module : "";

  let saveTrackAnalyticsParam = {
    "user_id": userStore.$state.userId,
    "customer_id": userStore.$state.customerId,
    "action": "PAGE ENTER",
    "module": pathModule,
    "remark": "",
    "ippublic": "",
    "iplocal": "",
    "value1": pathName,
    "value2": pathParams,
    "value3":"",
    "value4":"",
    "value5":""
  }

  trackAnalyticsStore.saveTrackAnalytics(saveTrackAnalyticsParam);
})

export default router;
