import LangPlugins from "./language";
import FormatPlugins from "./formater";
import JsonPlugins from "./json";
import ValidatorPlugins from "./validator";
import OtherPlugins from "./other";

const MyPlugin = {
  LangPlugins,
  FormatPlugins,
  JsonPlugins,
  ValidatorPlugins,
  OtherPlugins
};

//the exported plugin must be registered in main.js
export default MyPlugin;
