const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }

  return true;
};

const jsonReplacer = (match, pIndent, pKey, pVal, pEnd) => {
  var key = "<span class=json-key>";
  var val = "<span class=json-value>";
  var str = "<span class=json-string>";
  var r = pIndent || "";
  if (pKey) r = r + key + pKey.replace(/[": ]/g, "") + "</span>: ";
  if (pVal) r = r + (pVal[0] == '"' ? str : val) + pVal + "</span>";
  return r + (pEnd || "");
};

const jsonPrettyPrint = (obj) => {
  var jsonLine = /^( *)("[\w]+": )?("[^"]*"|[\w.+-]*)?([,[{])?$/gm;
  return JSON.stringify(obj, null, 3)
    .replace(/&/g, "&amp;")
    .replace(/\\"/g, "&quot;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(jsonLine, jsonReplacer);
};

const JsonPlugins = {
  isJson,
  jsonReplacer,
  jsonPrettyPrint,
};

export default JsonPlugins;
