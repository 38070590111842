import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { createMetaManager, defaultConfig } from "vue-meta";

//plugin and configs
import MyPlugin from "./plugins/plugin";
import vuetify from "./plugins/vuetify";
import languages from "./configs/language";
import axios from "axios";

import "./assets/css/main.scss";
import "./assets/css/main.css";
import "./assets/css/typography.css";

import "@mdi/font/css/materialdesignicons.css";

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const metaManager = createMetaManager(false, {
  ...defaultConfig,
  meta: { tag: "meta", nameless: true },
});

const app = createApp(App).use(pinia).use(router).use(metaManager).use(vuetify);

//register the plugins
app.config.globalProperties.axios = axios;
app.config.globalProperties.myPlugin = MyPlugin;
app.config.globalProperties.languages = languages;

app.mount("#app");
