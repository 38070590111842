<template>
  <v-app class="d-block">
    <Header />
    <slot />
    <Footer />

    <!-- Contact Us -->
    <ContactUsDialog />
  </v-app>
</template>

<script>
import Header from "../components/HeaderNav.vue";
import Footer from "../components/FooterNav.vue";
import ContactUsDialog from "../components/ContactUsDialog.vue";

export default {
  name: "AppLayout",
  components: { Header, Footer, ContactUsDialog },
};
</script>
