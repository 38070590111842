import mainAPI from "./mainAPI";

const contactAPI = {
  getContactPurpose() {
    return mainAPI.requestHandler(
      {
        "Content-Type": "application/json",
      },
      {
        apidata: {
          apirequest: "GETCONTACTPURPOSE",
          userid: "GUEST",
        },
        params: {},
      }
    );
  },
  sendUserMessage(forms) {
    return mainAPI.requestHandler(
      {
        "Content-Type": "application/json",
      },
      {
        apidata: {
          apirequest: "SENDUSERMESSAGE",
          userid: "GUEST",
        },
        params: {
          fullname: forms.name,
          phone: forms.phone,
          email: forms.email,
          purposeId: forms.purpose,
          message: forms.message,
        },
      }
    );
  },
};

export default contactAPI;
