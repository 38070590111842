<template>
  <div>
    <v-dialog width="500">
      <template v-slot:activator="{ props }">
        <v-btn
          v-bind="props"
          :text="this.$root.lang('contactContactUs')"
          size="large"
          class="dialog-btn text-white text-capitalize font-weight-bold rounded-t-lg"
          elevation="0"
        >
        </v-btn>
      </template>

      <template v-slot:default="{ isActive }">
        <v-card class="rounded-xl pa-3 pa-sm-6" color="#f9f9f9">
          <v-card-title class="text-h5 text-text-default">
            {{ this.$root.lang("contactContactUs") }}
          </v-card-title>

          <v-form @submit.prevent v-model="formValid" ref="formRef">
            <v-card-text>
              <div v-for="(content, idx) in contents" :key="idx">
                <div class="font-weight-bold text-text-default mb-2">
                  {{ this.$root.lang(content.label) }}
                </div>
                <!-- Menu Field -->
                <v-select
                  v-if="content.key == 'purpose'"
                  variant="outlined"
                  v-model="forms[content.key]"
                  :rules="rules[content.key]"
                  :items="purposeMsg"
                  item-title="description"
                  item-value="purposeid"
                  :placeholder="
                    this.$root.lang('contactFormRequirePlaceholder')
                  "
                />
                <!-- Text Area -->
                <v-textarea
                  v-else-if="content.key == 'message'"
                  variant="outlined"
                  v-model="forms[content.key]"
                  :rules="rules[content.key]"
                  :counter="4000"
                  :placeholder="
                    this.$root.lang('contactFormMessagePlaceholder')
                  "
                />
                <!-- Text Field -->
                <v-text-field
                  v-else
                  variant="outlined"
                  v-model="forms[content.key]"
                  :rules="rules[content.key]"
                  :placeholder="this.$root.lang(content.placeholder)"
                />
              </div>
            </v-card-text>

            <v-card-actions class="px-4">
              <v-btn
                :text="isIndonesia() ? 'Batal' : 'Cancel'"
                size="large"
                @click="isActive.value = false"
                class="font-weight-bold mr-4 text-secondary"
              />
              <v-btn
                type="submit"
                size="large"
                @click="submitMessage"
                class="font-weight-bold text-white bg-primary rounded-pill px-4"
                style="min-width: 150px; height: 60px"
              >
                <!-- When not loading show the text -->
                {{ loading ? "" : this.$root.lang("contactEmailCTA") }}
                <!-- When Loading hide text and show the circular progress -->
                <v-progress-circular
                  v-if="loading"
                  color="white"
                  :size="20"
                  indeterminate
                />
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </template>
    </v-dialog>

    <!-- Snackbar Notification -->
    <v-snackbar
      v-model="snackbar.model"
      :color="snackbar.status"
      :timeout="3000"
      location="top right"
    >
      <div class="text-center font-weight-bold text-body-2">
        {{ snackbar.msg }}
      </div>
    </v-snackbar>
  </div>
</template>

<script>
import MyPlugin from "@/plugins/plugin";
import Contact from "@/api/contact";
import { mapActions, mapState } from "pinia";
import { useContactStore } from "@/stores/ContactStore";
import { useTrackAnalyticsStore } from "@/stores/TrackAnalyticsStore";
import { useUserStore } from "@/stores/UserStore";

export default {
  name: "ContactUsDialog",
  data() {
    return {
      snackbar: {
        model: false,
        msg: "",
        status: "",
      },
      loading: false,
      forms: {
        name: "",
        phone: "",
        email: "",
        purpose: null,
        message: "",
      },
      formValid: false,
      rules: {
        name: [
          (v) =>
            !!v ||
            (MyPlugin.LangPlugins.isIndonesia()
              ? "Nama wajib diisi"
              : "Name is required"),
        ],
        phone: [
          (v) =>
            !!v ||
            (MyPlugin.LangPlugins.isIndonesia()
              ? "Nomor telepon wajib diisi"
              : "Phone number is required"),
          (v) =>
            !v ||
            /^[0-9]*$/.test(v) ||
            (MyPlugin.LangPlugins.isIndonesia()
              ? "Nomor telepon harus valid"
              : "Phone number must be valid"),
        ],
        email: [
          (v) =>
            !!v ||
            (MyPlugin.LangPlugins.isIndonesia()
              ? "Email wajib diisi"
              : "Email is required"),
          (v) =>
            !v ||
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            (MyPlugin.LangPlugins.isIndonesia()
              ? "E-mail harus valid"
              : "E-mail must be valid"),
        ],
        purpose: [
          (v) =>
            !!v ||
            (MyPlugin.LangPlugins.isIndonesia()
              ? "Keperluan wajib diisi"
              : "Purpose is required"),
        ],
        message: [
          (v) =>
            !!v ||
            (MyPlugin.LangPlugins.isIndonesia()
              ? "Pesan wajib diisi"
              : "Message is required"),
          (v) =>
            v.length <= 4000 ||
            (MyPlugin.LangPlugins.isIndonesia()
              ? "Pesan harus kurang dari 4000 karakter"
              : "Message must be less than 4000 characters"),
        ],
      },
      contents: [
        {
          key: "name",
          label: "contactFormName",
          placeholder: "contactFormNamePlaceholder",
        },
        {
          key: "phone",
          label: "contactFormWA",
          placeholder: "contactFormWAPlaceholder",
        },
        {
          key: "email",
          label: "contactFormEmail",
          placeholder: "contactFormEmailPlaceholder",
        },
        {
          key: "purpose",
          label: "contactFormRequire",
          placeholder: "contactFormRequirePlaceholder",
        },
        {
          key: "message",
          label: "contactFormMessage",
          placeholder: "contactFormMessagePlaceholder",
        },
      ],
    };
  },
  computed: {
    ...mapState(useContactStore, ["purposeMsg"]),
  },
  methods: {
    ...mapActions(useContactStore, ["setPurpose", "contactUsSendEmail"]),

    async submitMessage() {
      if (this.formValid) {
        this.loading = true;
        //Save data
        const submit = await Contact.sendUserMessage(this.forms);
        if (submit.data.data.status == "not-ok") {
          this.snackbar.msg = MyPlugin.LangPlugins.isIndonesia()
            ? "Gagal mengirim pesan"
            : "failed to send message";
          this.snackbar.status = "error";
          return;
        }

        //Send data to email
        const params = {
          name: this.forms.name,
          phone: this.forms.phone,
          email: this.forms.email,
          purpose: this.purposeMsg.find(
            (x) => x.purposeid == this.forms.purpose
          ).description,
          message: this.forms.message,
        };
        let contactStore = useContactStore();
        const sendEmailResp = await contactStore.contactUsSendEmail(params);
        if (
          sendEmailResp.status != 200 ||
          !sendEmailResp.data.isSuccess ||
          sendEmailResp.data.isSuccess == "not-ok"
        ) {
          this.snackbar.msg = MyPlugin.LangPlugins.isIndonesia()
            ? "Gagal mengirim pesan"
            : "failed to send message";
          this.snackbar.status = "error";
          return;
        }

        this.snackbar.msg = MyPlugin.LangPlugins.isIndonesia()
          ? "Pesan telah dikirim"
          : "the message has been sent";
        this.snackbar.status = "success";
        this.snackbar.model = true;
        this.$refs.formRef.reset();
        this.loading = false;

        // Save User Track Analytics
        this.saveTrackAnalytics(
          "SEND CONTACT US",
          "Send From Floating Contact Us",
          "",
          `{"email": "${params.email}", "purpose": "${params.purpose}"}`,
          "",
          "",
          ""
        );
      }
    },
    isIndonesia() {
      return MyPlugin.LangPlugins.isIndonesia();
    },
    saveTrackAnalytics(action, remark, val1, val2, val3, val4, val5) {
      let userStore = useUserStore();
      let trackAnalyticsStore = useTrackAnalyticsStore();

      let pathName =
        this.$route && this.$route.fullPath ? this.$route.fullPath : "";
      let pathParams =
        this.$route && this.$route.params
          ? JSON.stringify(this.$route.params)
          : "";
      let pathModule =
        this.$route && this.$route.meta && this.$route.meta.module
          ? this.$route.meta.module
          : "";

      let saveTrackAnalyticsParam = {
        user_id: userStore.$state.userId,
        customer_id: userStore.$state.customerId,
        action: action,
        module: pathModule,
        remark: remark,
        ippublic: "",
        iplocal: "",
        value1: val1 ? val1 : pathName,
        value2: val2 ? val2 : pathParams,
        value3: val3 ? val3 : "",
        value4: val4 ? val4 : "",
        value5: val5 ? val5 : "",
      };
      trackAnalyticsStore.saveTrackAnalytics(saveTrackAnalyticsParam);
    },
  },
  async mounted() {
    if (this.purposeMsg.length == 0) {
      const pps = await Contact.getContactPurpose();
      this.setPurpose(pps.data.data);
    }
  },
};
</script>

<style scoped>
.dialog-btn {
  position: fixed;
  top: 70vh;
  right: -68px;
  width: 180px;
  transform: rotate(-90deg);
  background: rgba(67, 67, 67, 0.4);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(4px);
}
.v-dialog {
  justify-content: right;
}
</style>
