import languages from "@/configs/language";
import { useGeneralStore } from "@/stores/GeneralStore";

const lang = (key) => {
  const choosedLanguage = useGeneralStore().$state.choosedLanguage;
  return languages[choosedLanguage][key];
};

const getLang = () => {
  return useGeneralStore().$state.choosedLanguage;
};

const isIndonesia = () => {
  return (
    useGeneralStore().$state.choosedLanguage.toLocaleLowerCase() == "indonesia"
  );
};

const LangPlugins = {
  lang,
  getLang,
  isIndonesia
};

export default LangPlugins;
