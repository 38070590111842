import { defineStore } from "pinia";

export const useGeneralStore = defineStore("GeneralStore", {
  state: () => ({
    choosedLanguage: "indonesia",
    activePage: "/",
  }),
  getters: {},
  actions: {
    toggleLanguage(state) {
      this.choosedLanguage = state;
    },
    setActivePage(state) {
      this.activePage = state;
    },
  },
  persist: true,
});
