const formatDateString = (dateString, type) => {
  //example args "2022-12-01"
  let date = new Date(dateString);
  return formatDate(date, type);
};

const formatDate = (_date, type) => {
  let date = new Date(_date);
  let year = date.getFullYear();
  let actMonth = date.getMonth() + 1;
  let month = actMonth < 10 ? "0" + actMonth : actMonth; //getMonth begin at 0
  let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

  let formattedDate = "";
  if (type == "date") {
    formattedDate = `${day}/${month}/${year}`;
  } else if (type == "datetime") {
    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();

    formattedDate = `${day}/${month}/${year} ${hour}:${minute}:${second}`;
  } else if (type == "timestamp") {
    let hour = date.getHours();
    let minute = date.getMinutes();
    let second = date.getSeconds();

    formattedDate = `${day}/${month}/${year}T${hour}:${minute}:${second}`;
  } else if (type == "date-en") {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const monthAbbreviation = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    formattedDate = `${monthAbbreviation}, ${day} ${year}`;
  }
  return formattedDate;
};

const FormatPlugins = {
  formatDate,
  formatDateString,
};

export default FormatPlugins;
