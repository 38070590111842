import { createVuetify } from "vuetify";
import * as components from "vuetify/components";
import * as directives from "vuetify/directives";

const customTheme = {
  dark: false,
  colors: {
    background: "#FFFFFF",
    primary: "#3A9542",
    "primary-darken-1": "#29682E",
    "primary-darken-2": "#1D4B21",
    "primary-light-1": "#B0D5B3",
    secondary: "#C8102E",
    "text-default": "#212121",
    "text-subdued": "#4D4D4D",
    gray: "#414244",
    "gray-light-1": "#EEEEEE",
  },
};

const vuetify = createVuetify({
  components,
  directives,
  theme: {
    defaultTheme: "customTheme",
    themes: {
      customTheme,
    },
  },
  icons: {
    defaultSet: "mdi",
  },
});

export default vuetify;
